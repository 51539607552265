import * as React from "react";
import Icon from "@sproutsocial/seeds-react-icon";
import { StyledSwitchButton } from "./styles";
import type { TypeSwitchProps } from "./SwitchTypes";

/**
 * @link https://seeds.sproutsocial.com/components/switch/
 *
 * Switch should always have an accessible label. Use aria-label, aria-labelledby or a `Label` component.
 * The accessible label should not change when the checked state changes.
 * The component uses role="switch" and the `checked` prop will be used to set the aria-checked attribute.
 *
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/switch/
 *
 * @example
 * <Switch checked={true} onClick={_onClick} aria-label="Switch Example" />
 */

const Switch = ({
  onClick,
  loading = false,
  checked,
  disabled = false,
  ...rest
}: TypeSwitchProps) => {
  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (!disabled) {
      onClick(e, !checked);
    }
  };
  return (
    <StyledSwitchButton
      type="button"
      role="switch"
      aria-checked={checked}
      disabled={disabled}
      onClick={handleClick}
      className={loading ? "loading" : ""}
      {...rest}
    >
      {checked ? <Icon aria-hidden size="mini" name="check-solid" /> : null}
    </StyledSwitchButton>
  );
};

export default Switch;
