import * as React from "react";
import type { EnumLogoNamesWithoutVariants } from "@sproutsocial/seeds-partner-logos";
import { LogoNamesWithoutVariants } from "@sproutsocial/seeds-partner-logos";
import type { TypeStyledComponentsCommonProps } from "@sproutsocial/seeds-react-system-props";
import type {
  TypeSystemCommonProps,
  TypeSystemFlexboxProps,
} from "@sproutsocial/seeds-react-system-props";

/**
 * @deprecated Use PartnerLogoNames instead
 */
export const PartnerNames = LogoNamesWithoutVariants;

/**
 * @deprecated Use EnumLogoNames instead
 */
export type TypePartnerNames = EnumLogoNamesWithoutVariants;

export const PartnerLogoSizes = {
  mini: "12px",
  small: "16px",
  medium: "24px",
  large: "32px",
  jumbo: "64px",
} as const;

export type TypePartnerLogoSize = keyof typeof PartnerLogoSizes;
export type TypePartnerLogoSizeValues =
  (typeof PartnerLogoSizes)[TypePartnerLogoSize];

export const PartnerLogoTypes = ["symbol", "wordmark", "lockup"] as const;

export type TypePartnerLogoTypes = (typeof PartnerLogoTypes)[number];

export interface TypePartnerLogoProps
  extends TypeStyledComponentsCommonProps,
    TypeSystemCommonProps,
    TypeSystemFlexboxProps,
    Omit<React.ComponentPropsWithoutRef<"span">, "color"> {
  /** The name of the partner whose logo you want to render. */
  partnerName: EnumLogoNamesWithoutVariants;

  /** Manually override the default mode behavior by rendering a logo based on the background it's displayed on. */
  backgroundType?: "dark" | "light";
  logoType?: TypePartnerLogoTypes;
  height?: string;
  width?: string;
  size?: TypePartnerLogoSize;
  svgProps?: React.ComponentPropsWithoutRef<"svg">;
}
