import React from "react";
import { useTheme } from "styled-components";
import type { TypeTheme } from "@sproutsocial/seeds-react-theme";
import Container from "./styles";
import type { TypePartnerLogoProps } from "./PartnerLogoTypes";
import { ViewBoxes } from "@sproutsocial/seeds-partner-logos";

const whichLogo = (
  {
    backgroundType,
    logoType,
    partnerName,
  }: Pick<TypePartnerLogoProps, "backgroundType" | "logoType" | "partnerName">,
  mode: TypeTheme["mode"]
) => {
  const logo =
    logoType && logoType !== "symbol"
      ? partnerName + "-" + logoType
      : partnerName;
  if (backgroundType && backgroundType === "dark") {
    return `${logo}-dark`;
  } else if (backgroundType && backgroundType === "light") {
    return logo;
  } else if (mode === "dark") {
    return `${logo}-dark`;
  } else {
    return logo;
  }
};

const PartnerLogo = ({
  partnerName,
  backgroundType,
  logoType = "symbol",
  height,
  width,
  size,
  "aria-label": ariaLabel,
  svgProps,
  ...rest
}: TypePartnerLogoProps) => {
  const { mode } = useTheme() as TypeTheme;

  const logo = whichLogo({ backgroundType, logoType, partnerName }, mode);
  const logoViewBox = ViewBoxes[logo as keyof typeof ViewBoxes];
  const viewBoxCoordinates = logoViewBox?.split(" ") || [];
  const defaultWidth = viewBoxCoordinates[2];
  const defaultHeight = viewBoxCoordinates[3];
  return (
    <Container //we add pixels here because view box coordinates are numbers
      height={height ? height : defaultHeight + "px"}
      width={width ? width : defaultWidth + "px"}
      logoSize={size}
      className="logo"
      role="img"
      aria-label={ariaLabel}
      data-qa-logo={partnerName}
      {...rest}
    >
      <svg
        className="logo-svg"
        viewBox={logoViewBox}
        focusable={false}
        data-qa-logo-svg={`${partnerName}-svg`}
        {...svgProps}
      >
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref={`#seeds-svgs_${logo}`}
        />
      </svg>
    </Container>
  );
};

export default PartnerLogo;
