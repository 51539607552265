import * as React from "react";
import { createPortal } from "react-dom";
import { canUseDOM } from "@sproutsocial/seeds-react-utilities";
import type { TypePortalProps } from "./PortalTypes";

export default class Portal extends React.Component<TypePortalProps> {
  el: HTMLElement | null | undefined = null;
  static defaultProps = {
    as: "div",
    id: "",
  };

  constructor(props: TypePortalProps) {
    super(props);

    if (canUseDOM()) {
      const { as, el, id } = props;

      if (el) {
        this.el = el;
      } else {
        this.el = document.createElement(as);
        this.el.setAttribute("data-racine-portal", id);
      }
    }
  }

  override componentDidMount() {
    if (canUseDOM() && this.el) {
      const { container = document.body } = this.props;

      if (container) {
        // for flow
        container.appendChild(this.el);
      }
    }
  }

  override componentWillUnmount() {
    if (canUseDOM() && this.el) {
      const { container = document.body } = this.props;

      if (container) {
        // for flow
        container.removeChild(this.el);
      }
    }
  }

  override render() {
    if (this.el) {
      return createPortal(this.props.children, this.el);
    }

    return this.props.children;
  }
}
